import React from "react";

const Button = React.forwardRef((props,ref) => (
    <React.Fragment>
      {props.title && <h1>{props?.title}</h1>}
      <span className={props.className} onClick={props.onClick}>
        {props.position === "left" && props.icon && (
          <i className={props.icon}></i>
        )}
        {props.content ? props.content : ""}
        {props.position === "right" && props.icon && (
          <i className={props.icon}></i>
        )}
        {props?.loader && (
          <i className="icon-spinner-solid-icon ml-5"></i>
        )}
      </span>
    </React.Fragment>
));

export default Button;
